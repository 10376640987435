import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Helmet } from "react-helmet";

function CaseStudy() {
  return (
    <>
      <Helmet>
        <title>Case Study - @Minds Technologies</title>
      </Helmet>
      <div>
        <div class="page-title">
          <div class="container">
            <h2>Case Study </h2>
            <h4>Agensy is compatible with popular WordPress plugins</h4>
          </div>
        </div>

        <img src="./assets/images/works-featured.jpg" />

        <section class="works">
          <div class="container">
            <ul>
              <li>
                <div class="titles">
                  <h2>EXPLORE OUR ALL WORKS</h2>
                  <h4>
                    Adding new features. Working to help your section business
                    grow. We’re constantly refining our product.
                  </h4>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works01.jpg" data-fancybox>
                      <img src="./assets/images/works01.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works01.jpg" data-fancybox>
                        Moments Matter Event Studio
                      </a>
                    </h3>
                    <small>Digital, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./images/" data-fancybox>
                      <img src="./assets/images/tv.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/tv.jpg" data-fancybox>
                        Comforter TV - OTT Platform
                      </a>
                    </h3>
                    <small>Development, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  /
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works04.jpg" data-fancybox>
                      <img src="./assets/images/works04.jpg" alt="Image" />{" "}
                    </a>
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works04.jpg" data-fancybox>
                        David Courtney IOS Application
                      </a>
                    </h3>
                    <small>Application, Branding</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works03.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works03.jpg" data-fancybox>
                        Stan's Office Online Gateway
                      </a>
                    </h3>
                    <small>Branding, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works05.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works05.jpg" data-fancybox>
                        Daria e-commerce IOS Application
                      </a>
                    </h3>
                    <small>eCommerce, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works06.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Get Yo Tacos IOS Application
                      </a>
                    </h3>
                    <small>Application, Mobile</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works07.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Sass Dashboard Application
                      </a>
                    </h3>
                    <small>Application, Dashboard</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works08.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Tropic Summer Digital Branding
                      </a>
                    </h3>
                    <small>Development, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works09.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Freed Official Web Site
                      </a>
                    </h3>
                    <small>Branding, Digital, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works10.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Electric Bike IOS Application
                      </a>
                    </h3>
                    <small>Branding, Print</small>{" "}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default CaseStudy;
