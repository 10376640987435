import Axios from "axios";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Moment from 'moment';

export default function CategoryBlog() {
  const [category, setCategory] = useState([]);
  const [posts, setPosts] = useState([]);

  const [catposts, setcatPosts] = useState([]);
  let {id} = useParams();

  const apiUrl = "https://backend.atminds.com/";
  const imgApiUrl = "https://demo.atminds.com/public/storage/";

  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/categories`).then((data) => {
      console.log(data);
      setCategory(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/category/${id}`).then((data) => {
      console.log('cat_post',data);
      setcatPosts(data.data);
    });
  }, []);
  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/post`).then((data) => {
      console.log(data);
      setPosts(data.data);
    });
  }, []);


  return (
    <>
      <div>
        <div class="page-title">
          <div class="container">
            <h2> blog </h2>
            <hr />
          </div>
        </div>

        <section class="blog">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
              {catposts.map((user, key) => (
              
                <div class="post wow fadeIn">
                  <figure class="post-image">
                    <img src={`${imgApiUrl}`+ user.post_image } alt="Image" />
                  </figure>
                  <div class="post-content">
                    <div class="avatar">
                      <img
                        src="./assets/images/53a98c10f3d7f4ec0c25e8f7d65b622a.png"
                        alt="Image"
                      />
                      by
                      <a href="#" className="pl-2">Admin</a>
                    </div>
                    <h3 class="post-title">{ user.post_name }</h3>
                    <small class="post-date">{Moment(user.post_created).format('DD MMM, yyyy')}</small>
                    <p class="post-intro">
                    { user.post_shot_dec }
                    </p>
                    <a href="/#" class="post-link">
                     <Link to={`/blog-detail/${user.post_id}`}>READ MORE</Link> 
                    </a>
                  </div>
                </div>
                ))}
              </div>

              <div class="col-lg-4">
                <aside class="sidebar">
                  <div class="widget">
                    {/* <div class="widget">
                      <h4 class="title">SEARCH</h4>
                      <form>
                        <input type="text" placeholder="Search on blog" />
                        <button type="submit">SEARCH</button>
                      </form>
                    </div> */}

                    <div class="widget">
                      <h4 class="title">RECENT POSTS</h4>
                      <ul class="recent">
                        {posts.map((user, key) => (
                          <li>
                            <a href="#"><Link to={`/blog-detail/${user.id}`}>{user.name}</Link></a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div class="widget">
                      <h4 class="title">CATEGORIES</h4>
                      <ul class="tags">
                        {category.map((user, key) => (
                          <li>
                            <a href="#"><Link to={`/category/${user.id}`}>{user.name}</Link></a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
