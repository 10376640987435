import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Moment from "moment";
// import { Form, Button } from "semantic-ui-react";
// import { useForm } from "react-hook-form";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

export default function ReachUs() {
  function onChange(value) {
    console.log("Captcha value:", value);
  }
  //   const showToastMessage = () => {
  //     toast.success('Success Notification !', {
  //         position: toast.POSITION.TOP_RIGHT
  //     });
  // };

  const apiUrl = "https://backend.atminds.com/";

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const submitPost = (event) => {
    event.preventDefault();
    axios.post(`${apiUrl}/api/create/reachus`, {
      name: name,
      email: email,
      subject: subject,
      content: content,
      created_at: new Date(),
    });
      document.getElementById('name').value='';
      document.getElementById('mail').value='';
      document.getElementById('subject').value='';
      document.getElementById('content').value='';
      // setRefreshReCaptcha(false);


    
      // setRefreshReCaptcha(!refreshReCaptcha);
    // setRefreshReCaptcha(r => !r);

    // toast.success('Success Notification !', {
    //   position: toast.POSITION.TOP_RIGHT
    // });
  };
  // const doSomething = () => {
  //   /* do something like submit a form and then refresh recaptcha */
  //   setRefreshReCaptcha((r) => !r);
  // };

  // const handleSubmit = (event) => {
  //   event.preventDefault();

  //   axios
  //     .post("http://localhost/react_crud/api/user/save", inputs)
  //     .then(function (response) {
  //       console.log(response.data);
  //       navigate("/");
  //     });
  // };

  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();
  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  return (
    <>
      <Helmet>
        <title>Reach Us - @Minds Technologies</title>
      </Helmet>
      <div>
        <div class="page-title">
          <div class="container">
            <h2>Reach Us</h2>
            <h4>
              We thank you for your time and interest in @Minds and its
              products. We are committed to offering the best to our customers,
              and we welcome your questions, comments, suggestions and requests
              for more information about our products and services.
            </h4>
          </div>
        </div>
        <section class="page-header">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31087.71505413231!2d80.214376!3d13.101443000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x232f78aaca2790be!2sState%20Bank%20of%20India%20ICF%20BRANCH%20CHENNAI!5e0!3m2!1sen!2sin!4v1671698567411!5m2!1sen!2sin"></iframe>
        </section>

        <section class="contact">
          <div class="container">
            <div class="row">
              <div class="col-md-6 wow fadeInUp">
                <h5>ADDRESS</h5>
                <address>
                  <strong>@Minds Technologies</strong>
                  <br />
                  163, South Thirumalai nagar
                  <br />
                  ICF, Chennai 600049, Tamil Nadu.
                </address>
              </div>

              <div class="col-md-6 wow fadeInUp">
                <h5>CALL US</h5>
                <address>
                  <a href="tel:+91 89396 77750">m : +91 89396 77750</a>
                  <br />

                  <a href="tel: +972 33 760811"> m: (+972) 33 760811</a>
                </address>
              </div>

              <div class="col-12 wow fadeInUp">
                <h5>REACH US</h5>
                <address>
                  <p>
                    We allways looking for talented peoples, soo let's work
                    together{" "}
                    <a href="mailto:hello@atminds.com">hello@atminds.com</a>
                  </p>
                </address>
              </div>

              <div class="col-lg-7 wow fadeInUp">
                <div class="contact-form">
                  {/* <h5>CONTACT FORM</h5> */}
                  {/* <ToastContainer /> */}
                  <form id="contact" name="contact">
                    {/* <div class="form-group">
                      <input
                      
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="off"
                        required
                        onChange={(e)=> {setName(e.target.value)}}
                      />
                      <span>Your name</span>
                    </div> */}

                    {/* <div class="form-group">
                      <input
                        type="text"
                        name="email"
                        validate='required,isEmail,isLength:5:60'
                        id="mail"
                        autocomplete="off"
                        required
                        onChange={(e)=> {setEmail(e.target.value)}}
                      />
                      <span>Your e-mail</span>
                    </div> */}

                    {/* <div class="form-group">
                      <input
                        type="text"
                        name="subject"
                        id="subject"
                        autocomplete="off"
                        required
                        onChange={(e)=> {setSubject(e.target.value)}}
                      />
                      <span>Subject</span>
                    </div> */}

                    {/* <div class="form-group">
                      <textarea
                        type="text"

                        name="content"
                        id="content"
                        autocomplete="off"
                        required
                        onChange={(e)=> {setContent(e.target.value)}}
                      />
                           <span>Your message</span>
                    </div> */}

                    {/* <div class="form-group">
                      <ReCAPTCHA 
                        sitekey="6LePJpsjAAAAANVTTIYAh_qeZzvK2VIpd3UZL-hM"
                        onChange={onChange}
                      />
                     
                    </div> */}

                    {/* <div class="form-group">
                      <button id="submit" onClick={submitPost}  type="submit" name="submit">
                        <strong>
                          Submit Now<b></b> <i></i>
                        </strong>
                      </button>
                    </div> */}

                    {/* <Form.Field>
                      <label> Name</label>
                      <input
                        placeholder=" Name"
                        type="text"
                        name="name"
                        id="name"
                        autocomplete="off"
                        required
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                    </Form.Field>
                    {errors.firstName && <p>Please check the First Name</p>}

                    <Form.Field>
                      <label style={{ marginTop: "15px" }}>Email</label>
                      <br />
                      <input
                        style={{
                          width: "640px",
                          border: "1px solid #eee",
                          padding: "10px",
                        }}
                        placeholder="Email"
                        type="email"
                        id="mail"
                        name="email"
                        autocomplete="off"
                        required
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        
                      />
                    </Form.Field>
                    {errors.email && <p>Please check the Email</p>}

                    <Form.Field>
                      <label style={{ marginTop: "15px" }}>Subject</label>
                      <input
                        placeholder="Subject"
                        type="text"
                        name="subject"
                        id="subject"
                        autocomplete="off"
                        required
                        onChange={(e) => {
                          setSubject(e.target.value);
                        }}
                      />
                    </Form.Field>

                    <Form.Field>
                      <label style={{ marginTop: "15px" }}>Message</label>
                      <textarea
                        placeholder="message"
                        type="text"
                        name="content"
                        id="content"
                        autocomplete="off"
                        required
                        onChange={(e) => {
                          setContent(e.target.value);
                        }}
                      />
                    </Form.Field> */}

                    {/* <div class="form-group mt-3">
                      <ReCAPTCHA
                        sitekey="6LePJpsjAAAAANVTTIYAh_qeZzvK2VIpd3UZL-hM"
                        onChange={onChange}
                     
                      />

                      
                    </div>

                    <div class="form-group mt-5">
                      <button
                        id="submit"
                        onClick={submitPost}
                        type="submit"
                        name="submit"
                      >
                        <strong>
                          Submit Now<b></b> <i></i>
                        </strong>
                      </button>
                    </div> */}
                  </form>

                  <div class="form-group">
                    <div
                      id="success"
                      class="alert alert-success wow fadeInUp"
                      role="alert"
                    >
                      {" "}
                      Your message was sent successfully! We will be in touch as
                      soon as we can.{" "}
                    </div>

                    <div
                      id="error"
                      class="alert alert-danger wow fadeInUp"
                      role="alert"
                    >
                      {" "}
                      Something went wrong, try refreshing and submitting the
                      form again.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
