import React, { Component } from "react";

import { Link, NavLink } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


function Home() {
  return (
    <>
      <section class="slider">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              data-background="assets/images/AtMinds_mobileapp.jpg"
            >
              <div class="slide-inner">
                <figure>
                  <img src="./assets/images/atmindsNewlogo.png" alt="Image" />
                </figure>
                <h2>Al and Data Science R&D Project Service</h2>
                <div class="link">
                  <a href="#">VISIT</a>
                </div>
              </div>
            </div>

            <div
              class="swiper-slide"
              data-background="assets/images/Kazh_atmindsApplause.jpg"
            >
              <div class="slide-inner">
                <figure>
                  <img src="/assets/images/kazh_ilogojan2021.png" alt="Image" />
                </figure>
                <h2>People Are Worth The Investment</h2>
                <div class="link">
                  <a href="#">VISIT</a>
                </div>
              </div>
            </div>
          </div>

          <div class="swiper-pagination"></div>

          <div class="swiper-button-next">
            <span></span>
            <b>NEXT</b>
          </div>
        </div>
      </section>

      <section class="featured-services" data-color="dark">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="titles">
                <h6>What We Do</h6>
                <p>
                  At @Minds, we continue to innovate, developing technology so
                  you are always ready to move forward. Learn more about digital
                  transformation and emerging technologies, and see real stories
                  from our customers.
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-md-3">
              <figure class="reveal-effect masker wow">
                {" "}
                <img src="/assets/images/icon06.png" alt="Image" />
                <figcaption>
                  <span>01</span>
                  <h4>AI and Data science R&D Project Services</h4>
                </figcaption>
              </figure>
            </div>

            <div class="col-lg-3 col-md-3">
              <figure class="reveal-effect masker wow">
                {" "}
                <img src="/assets/images/icon05.png" alt="Image" />
                <figcaption>
                  <span>02</span>
                  <h4>AI Implementation and deployment</h4>
                </figcaption>
              </figure>
            </div>

            <div class="col-lg-3 col-md-3">
              <figure class="reveal-effect masker wow">
                {" "}
                <img src="/assets/images/icon02.png" alt="Image" />
                <figcaption>
                  <span>03</span>
                  <h4>Enterprise software project services</h4>
                </figcaption>
              </figure>
            </div>

            <div class="col-lg-3 col-md-3">
              <figure class="reveal-effect masker wow">
                {" "}
                <img src="/assets/images/icon08.png" alt="Image" />
                <figcaption>
                  <span>04</span>
                  <h4>Cloud Services - Transform your digital journey</h4>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>

      <section class="works">
        <div class="container">
          <ul>
            <li>
              <div class="titles">
                <h2>Explore recent works</h2>
                <h4>
                  We’re constantly refining our product. Adding new features.
                  Working to help your section business grow.
                </h4>
                <div class="custom-btn">
                  <a href="#">
                    LERAN MORE<span></span> <i></i>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="project-box">
                <figure class="project-image reveal-effect masker wow">
                  {" "}
                  <a href="assets/images/works01.jpg" data-fancybox>
                    <img src="assets/images/works01.jpg" alt="Image" />
                  </a>{" "}
                </figure>
                <div class="project-content">
                  <h3>
                    <NavLink
                      to="/portfolio/moments-matter-event-studio"
                      data-fancybox
                    >
                      Moments Matter Event Studio
                    </NavLink>
                  </h3>
                  <small>Digital, eCommerce, Print</small>{" "}
                </div>
              </div>
            </li>
            <li>
              <div class="project-box">
                <figure class="project-image reveal-effect masker wow">
                  {" "}
                  <a href="assets/images/tv1.jpg" data-fancybox>
                    <img src="assets/images/tv1.jpg" alt="Image" />
                  </a>{" "}
                </figure>
                <div class="project-content">
                  <h3>
                    <NavLink to="/portfolio/comforter-tv-ott" data-fancybox>
                      Comforter TV - OTT Platform
                    </NavLink>
                  </h3>
                  <small>Development, Web Design</small>{" "}
                </div>
              </div>
            </li>
            <li>
              <div class="project-box">
                <figure class="project-image reveal-effect masker wow">
                  {" "}
                  <a href="assets/images/works04.jpg" data-fancybox>
                    <img src="assets/images/works04.jpg" alt="Image" />{" "}
                  </a>
                </figure>
                <div class="project-content">
                  <h3>
                    <NavLink
                      to="/portfolio/david-courtney-ios-application"
                      data-fancybox
                    >
                      David Courtney IOS Application
                    </NavLink>
                  </h3>
                  <small>Application, Branding</small>{" "}
                </div>
              </div>
            </li>
            <li>
              <div class="project-box">
                <figure class="project-image reveal-effect masker wow">
                  {" "}
                  <a href="assets/images/works03.jpg" data-fancybox>
                    <img src="assets/images/works03.jpg" alt="Image" />
                  </a>{" "}
                </figure>
                <div class="project-content">
                  <h3>
                    <NavLink
                      to="/portfolio/stans-office-online-gateway"
                      data-fancybox
                    >
                      Stan's Office Online Gateway
                    </NavLink>
                  </h3>
                  <small>Branding, eCommerce, Print</small>{" "}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      {/* <section class="recent-news">
          <div class="container">
            <div class="row align-items-end">
              <div class="col-12 wow fadeInUp">
                <div class="content-box selected">
                  <small>Edge Intelligence</small>
                  <h3>
                    <a href="blog-single.html">
                      What if ? Things Start to think
                    </a>
                  </h3>
                  <span>March 13, 2020</span>
                </div>
              </div>

              <div class="col-lg-4 wow fadeInUp">
                <div class="content-box">
                  <small>development, security</small>
                  <h3>
                    <a href="blog-single.html">
                      Performed suspicion in certainty so frankness by attention
                      pretended.
                    </a>
                  </h3>
                  <span>March 13, 2020</span>
                </div>
              </div>

              <div class="col-lg-4 wow fadeInUp">
                <div class="content-box">
                  <small>browser, creative</small>
                  <h3>
                    <a href="blog-single.html">
                      Three - piece set with matching headband and knickers.
                    </a>
                  </h3>
                  <span>March 13, 2020</span>
                </div>
              </div>

              <div class="col-lg-4 wow fadeInUp">
                <div class="content-box">
                  <small>security, wordpress</small>
                  <h3>
                    <a href="blog-single.html">
                      Extremity excellent certainty discourse sincerity no he so
                      resembled.
                    </a>
                  </h3>
                  <span>March 13, 2020</span>
                </div>
              </div>
            </div>
          </div>
        </section> */}

      <section class="showreel" data-color="dark">
        <div class="container">
          <div class="video reveal-effect masker wow">
            <video
              src="/assets/videos/videoplayback.mp4"
              autoplay
              loop
              muted
            ></video>
          </div>
        </div>
      </section>

      <section class="logos">
        <div class="container">
          <div class="titles">
            <h6>CLIENTS WE’VE WORKED WITH</h6>
            <p>
              We are very fortunate to have formed excellent partnerships with
              many of our clients. And we’ve formed more than just working
              relationships with them; we have formed true friendships.
            </p>
          </div>

          <ul class="wow fadeInUp">
            <li>
              <figure>
                <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
                <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/Waypoint.png" alt="Image" />
                <img src="/assets/images/Waypoint.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/CuticaHealth.png" alt="Image" />
                <img src="/assets/images/CuticaHealth.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/CDP.png" alt="Image" />
                <img src="/assets/images/CDP.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/CobbleHyde.png" alt="Image" />
                <img src="/assets/images/CobbleHyde.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/CPTCSA.png" alt="Image" />
                <img src="/assets/images/CPTCSA.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/ECGroup.png" alt="Image" />
                <img src="/assets/images/ECGroup.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/hecs_logo.png" alt="Image" />
                <img src="/assets/images/hecs_logo.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/Jebathottam.png" alt="Image" />
                <img src="/assets/images/Jebathottam.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/Comforter.png" alt="Image" />
                <img src="/assets/images/Comforter.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/JFamilychannel.png" alt="Image" />
                <img src="/assets/images/JFamilychannel.png" alt="Image" />
              </figure>
            </li>

            <li>
              <figure>
                <img src="/assets/images/Bimarketing.png" alt="Image" />
                <img src="/assets/images/Bimarketing.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/jesuscalls.png" alt="Image" />
                <img src="/assets/images/jesuscalls.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/Mizpah.png" alt="Image" />
                <img src="/assets/images/Mizpah.png" alt="Image" />
              </figure>
            </li>
            <li>
              <figure>
                <img src="/assets/images/Sathiya.png" alt="Image" />
                <img src="/assets/images/Sathiya.png" alt="Image" />
              </figure>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default Home;
