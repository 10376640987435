import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

function CaseStudyDetail() {
  return (
    <>
      <div class="page-title">
        <div class="container pb-5">
          <h2>Works</h2>
        </div>
      </div>

      <section class="page-header mt-5">
        <a href="#">
          <img src="../assets/images/case01.png" alt="Image" />
        </a>
      </section>

      <section class="about-intro">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2
                style={{
                  fontSize: "22px",
                  marginTop: "-90px",
                  fontWeight: "600",
                }}
              >
                CASE REVIEW
              </h2>
              <p
                style={{
                  width: "70%",
                  fontWeight: "500",
                  marginTop: "-20px",
                  lineHeight: "25px",
                }}
              >
                Solution for the morning preparation with every guy’s first date
                in mind by creating a neat branding finding a self lenguage with
                the minimal elements possible. Taking care about everything goes
                right at that special day is the main goal and best briefing
                possible.
              </p>
            </div>

            <div class="col-md-6">
              <p>
                <strong>CASE TIMELINE</strong>
              </p>
              <h5>2018 – 2019 September</h5>
            </div>

            <div class="col-md-6">
              <p>
                <strong>BRAND </strong>
              </p>
              <h5>David Courtney | Digital Works</h5>
            </div>
          </div>
        </div>
      </section>

      <section class="page-header mt-5">
        <img src="../assets/images/case02.png" alt="Image" />
      </section>

      <section class="page-header mt-5">
        <img src="../assets/images/case03.png" alt="Image" />
      </section>

      <section class="page-header mt-5">
        <img src="../assets/images/case04.png" alt="Image" />
      </section>

      <section class="team">
        <div className="row">
          <div class="col-lg-6 col-md-6">
            <img src="../assets/images/case05.png" alt="Image" />
          </div>

          <div class="col-lg-6 col-md-6">
            <img src="../assets/images/case06.png" alt="Image" />
          </div>
        </div>
      </section>

      <section class="page-header ">
        <img src="../assets/images/case07.png" alt="Image" />
      </section>

      <section class="about-intro">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>Thanks for Watching</h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CaseStudyDetail;
