import React, { Component } from "react";
import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from "react-router-dom";
import Home from "./Home";

// import Header from "./common/Header";
import Blog from "./Blog";
import CategoryBlog from "./CategoryBlog";
import BlogDetail from "./BlogDetail";
import CaseStudy from "./CaseStudy";
import CaseStudyDetail from "./CaseStudyDetail";
import WhatWeDo from "./WhatWeDo";
import WhoWeAre from "./WhoWeAre";
import ReachUs from "./ReachUs";
// import Footer from "./common/Footer";
function Header() {
  return (
    <>
    <BrowserRouter forceRefresh={false} />
      <BrowserRouter>
        <>
          <div class="transition-overlay"></div>
          <div class="navigation-menu">
            <div class="inner">
              <div class="side-menu">
                <ul>
                  <li>
                    <a href="/#">
                      <Link to="/">Home</Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/who-we-are">Who We Are</Link>
                    </a>
                  </li>
                  <li>
                    <Link to="/what-we-do">
                      <a href="/#">What We Do</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/case-study">
                      <a href="/#">Case Study</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog">
                      <a href="/#">Blog</a>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reach-us">
                      <a href="/#">Reach Us</a>
                    </Link>{" "}
                  </li>
                </ul>
              </div>
              <div class="sides">
                <figure>
                  <img src="assets/images/office01.jpg" alt="Images" />
                  <a href="#map" data-fancybox>
                    <img
                      src="assets/assets/images/icon-map-marker.svg"
                      alt="Images"
                    />
                  </a>
                </figure>
              </div>
              <div class="sides">
                <h2>
                  AtMinds Technologies
                  <br /> -<strong>Tech Minds</strong> at work
                </h2>
                <address>
                  @Minds Technologies
                  <br />
                  163, South Thirumalai nagar
                  <br />
                  ICF, Chennai 600049, Tamil Nadu
                  <br />
                  <a href="/#">hello@atminds.com </a>
                  +918939677750
                </address>
              </div>
            </div>
          </div>
          <aside class="left-side">
            <div class="logo">
              <img src="/assets/images/atmindsNewicon.png" alt="Images" />
            </div>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/atminds"
                  target="_blank"
                  rel="noreferrer"
                >
                  FACEBOOK
                </a>
              </li>
              <li>
                <a href="/#">LINKEDIN</a>
              </li>
              <li>
                <a href="/#">TWITTER</a>
              </li>
            </ul>
            <a href="#top" class="gotop">
              <img src="/assets/images/icon-gotop.svg" alt="Images" />
            </a>
          </aside>
          <header class="header">
            <nav class="navbar">
              <div class="logo">
                {" "}
                <a href="/">
                  <img src="/assets/images/atmindsNewlogo.png" alt="Images" />
                </a>{" "}
              </div>

              <div class="main-menu">
                <ul>
                  <li>
                    <a href="/#">
                      <Link to="/">Home </Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/who-we-are">Who We Are </Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/what-we-do">What We Do</Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/case-study">Case Study </Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/blog">Blog</Link>
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      <Link to="/reach-us">Reach Us</Link>{" "}
                    </a>
                  </li>
                </ul>
              </div>
              <div class="hamburger-menu" id="hamburger-menu">
                <div class="burger">
                  <svg
                    id="burger-svg"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 50 50"
                  >
                    <title>Show / Hide Navigation</title>
                    <rect class="burger-svg__base" width="50" height="50" />
                    <g class="burger-svg__bars">
                      <rect
                        class="burger-svg__bar burger-svg__bar-1"
                        x="14"
                        y="18"
                        width="22"
                        height="2"
                      />
                      <rect
                        class="burger-svg__bar burger-svg__bar-2"
                        x="14"
                        y="24"
                        width="22"
                        height="2"
                      />
                      <rect
                        class="burger-svg__bar burger-svg__bar-3"
                        x="14"
                        y="30"
                        width="22"
                        height="2"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </nav>
          </header>
        </>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/what-we-do" element={<WhatWeDo />} />
          <Route path="/case-study" element={<CaseStudy />} />
          <Route
            path="/portfolio/moments-matter-event-studio"
            element={<CaseStudyDetail />}
          />
          <Route
            path="/portfolio/comforter-tv-ott"
            element={<CaseStudyDetail />}
          />
          <Route
            path="/portfolio/david-courtney-ios-application"
            element={<CaseStudyDetail />}
          />
          <Route
            path="/portfolio/stans-office-online-gateway"
            element={<CaseStudyDetail />}
          />
          <Route path="/blog" element={<Blog />} />
          <Route path="/category/:id" element={<CategoryBlog />} />
          <Route path="/blog-detail/:id" element={<BlogDetail />} />
          <Route path="/reach-us" element={<ReachUs />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Header;
