import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Helmet } from "react-helmet";

function WhatWeDo() {
  return (
    <>
      <Helmet>
        <title>What We Do - @Minds Technologies</title>
      </Helmet>
      <div>
        <div class="page-title">
          <div class="container">
            <h2>What We Do</h2>
            <h4>Learn more about @Minds Technologies</h4>
          </div>
        </div>

        <section class="page-header">
          <div class="video-bg">
            <video
              src="./assets/videos/Lets-recognise-the-people-who-live-these-values-every-day-on-the-job.-Thats-the-purpose-of-our-global-Appreciation-Rewards-and-Recognition-programme.mp4"
              loop
              autoplay
              muted
            ></video>
          </div>
        </section>

        <section class="about-intro">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h2>What We Do</h2>
                <p>
                  We work in every major area of technology. We provide a wide
                  array of technical assistance, and we help companies share and
                  apply innovative knowledge and solutions to the challenges
                  they face.
                </p>
              </div>

              <div class="col-md-4">
                <figure class="logo">
                  {" "}
                  <img src="./assets/images/icon1-1.png" alt="Image" />{" "}
                </figure>
                <h6>1</h6>
                <h3>Cloud Migration</h3>

                <p>
                  Cloud Transformation services and solutions enable enterprises
                  to simplify migration planning.
                </p>
                <div class="custom-btn mt-4">
                  <a href="#">
                    LERAN MORE<span></span> <i></i>
                  </a>
                </div>
              </div>

              <div class="col-md-4">
                <figure class="logo">
                  {" "}
                  <img src="./assets/images/icon2-1.png" alt="Image" />{" "}
                </figure>
                <h6>2</h6>
                <h3>Data labeling services </h3>
                <p>
                  We accurately label the datasets required for training Machine
                  Learning systems.
                </p>
                <div class="custom-btn mt-4">
                  <a href="#">
                    LERAN MORE<span></span> <i></i>
                  </a>
                </div>
              </div>

              <div class="col-md-4">
                <figure class="logo">
                  {" "}
                  <img src="./assets/images/icon1.png" alt="Image" />{" "}
                </figure>
                <h6>3</h6>
                <h3>AI Solutions Development</h3>
                <p>
                  Creating value for startups and enterprises by building
                  AI-powered products.
                </p>
                <div class="custom-btn mt-4">
                  <a href="#">
                    LERAN MORE<span></span> <i></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="logos">
          <div class="container">
            <div class="titles">
              <h6>CLIENTS WHO TRUST US</h6>
              <p>
                We’re constantly refining our product. Adding new features.
                Working to help your business grow.
              </p>
            </div>

            <ul class="wow fadeInUp">
              <li>
                <figure>
                  <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
                  <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Waypoint.png" alt="Image" />
                  <img src="/assets/images/Waypoint.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CuticaHealth.png" alt="Image" />
                  <img src="/assets/images/CuticaHealth.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CDP.png" alt="Image" />
                  <img src="/assets/images/CDP.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CobbleHyde.png" alt="Image" />
                  <img src="/assets/images/CobbleHyde.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CPTCSA.png" alt="Image" />
                  <img src="/assets/images/CPTCSA.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/ECGroup.png" alt="Image" />
                  <img src="/assets/images/ECGroup.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/hecs_logo.png" alt="Image" />
                  <img src="/assets/images/hecs_logo.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Jebathottam.png" alt="Image" />
                  <img src="/assets/images/Jebathottam.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Comforter.png" alt="Image" />
                  <img src="/assets/images/Comforter.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/JFamilychannel.png" alt="Image" />
                  <img src="/assets/images/JFamilychannel.png" alt="Image" />
                </figure>
              </li>

              <li>
                <figure>
                  <img src="/assets/images/Bimarketing.png" alt="Image" />
                  <img src="/assets/images/Bimarketing.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/jesuscalls.png" alt="Image" />
                  <img src="/assets/images/jesuscalls.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Mizpah.png" alt="Image" />
                  <img src="/assets/images/Mizpah.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Sathiya.png" alt="Image" />
                  <img src="/assets/images/Sathiya.png" alt="Image" />
                </figure>
              </li>
            </ul>
          </div>
        </section>

        <section class="work-with-us" data-color="dark">
          <div class="container wow fadeInUp">
            <h6>WORK WITH PRO TEAM</h6>
            <h2>
              Would you like to have professional project? Let's talk about it!
            </h2>
            <p>
              {" "}
              <a href="mailto:hello@atminds.com">hello@atminds.com</a>
            </p>
            <small>Or</small>
            <p>
              <a href="tel:+91 89396 77750">+91 89396 77750</a>
            </p>
          </div>
        </section>

        <section class="works">
          <div class="container">
            <ul>
              <li>
                <div class="titles">
                  <h2>EXPLORE OUR ALL WORKS</h2>
                  <h4>
                    Adding new features. Working to help your section business
                    grow. We’re constantly refining our product.
                  </h4>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works01.jpg" data-fancybox>
                      <img src="./assets/images/works01.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works01.jpg" data-fancybox>
                        Moments Matter Event Studio
                      </a>
                    </h3>
                    <small>Digital, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./images/" data-fancybox>
                      <img src="./assets/images/tv.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/tv.jpg" data-fancybox>
                        Comforter TV - OTT Platform
                      </a>
                    </h3>
                    <small>Development, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works04.jpg" data-fancybox>
                      <img src="./assets/images/works04.jpg" alt="Image" />{" "}
                    </a>
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works04.jpg" data-fancybox>
                        David Courtney IOS Application
                      </a>
                    </h3>
                    <small>Application, Branding</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works03.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works03.jpg" data-fancybox>
                        Stan's Office Online Gateway
                      </a>
                    </h3>
                    <small>Branding, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works05.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works05.jpg" data-fancybox>
                        Daria e-commerce IOS Application
                      </a>
                    </h3>
                    <small>eCommerce, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works06.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Get Yo Tacos IOS Application
                      </a>
                    </h3>
                    <small>Application, Mobile</small>{" "}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default WhatWeDo;
