import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Helmet } from "react-helmet";

function WhoWeAre() {
  return (
    <>
      <Helmet>
        <title>Who We Are - @Minds Technologies</title>
      </Helmet>
      <div>
        <div class="page-title">
          <div class="container">
            <h2>Who We Are</h2>
            <h4>Learn more about @Minds Technologies</h4>
          </div>
        </div>

        <section class="page-header">
          <div class="video-bg">
            <video
              src="./assets/videos/Lets-recognise-the-people-who-live-these-values-every-day-on-the-job.-Thats-the-purpose-of-our-global-Appreciation-Rewards-and-Recognition-programme.mp4"
              loop
              autoplay
              muted
            ></video>
          </div>
        </section>

        <section class="about-intro">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h2>About AtMinds Technologies</h2>
                <p>
                  Our Company AtMinds Technologies was initially called AtMinds
                  and has been in existence since 2007. It was founded on the
                  word of God, You will be a crown of splendor in the LORD’s
                  hand, a royal diadem in the hand of your God.
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  Wisdom and Knowledge has been the base of this organization,
                  thus we not only keep up with the current trend in the world
                  market but also like to explore the technology and have a
                  niche advantage over the others.
                </p>
              </div>

              <div class="col-md-6">
                <p>
                  We stretch our boundaries in all the field of IT, starting
                  with Web design, Enterprise applications, Mobile applications,
                  branding to the latest technology like Edge Intelligence,
                  Artificial General Intelligence, Bot Development, Blockchain.
                  Making the life of our customers more splendid with this
                  medium of information technology is our Aim, Goal and Motive.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section class="main-services" data-color="dark">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="titles">
                  <h6>PROIT SERVICES</h6>
                  <p>
                    We’re constantly refining our product. Adding new features.
                    Working to help your business grow.
                  </p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon01.png" alt="Image" />
                </figure>
                <article>
                  <span>01</span>
                  <h4>UI-UX DESIGN</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon02.png" alt="Image" />
                </figure>
                <article>
                  <span>02</span>
                  <h4>DESIGN CODING</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon03.png" alt="Image" />
                </figure>
                <article>
                  <span>03</span>
                  <h4>BRANDING</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon04.png" alt="Image" />
                </figure>
                <article>
                  <span>04</span>
                  <h4>MARKETTING</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon05.png" alt="Image" />
                </figure>
                <article>
                  <span>05</span>
                  <h4>IOS APPS</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon06.png" alt="Image" />
                </figure>
                <article>
                  <span>06</span>
                  <h4>CUSTOM CMS</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon07.png" alt="Image" />
                </figure>
                <article>
                  <span>07</span>
                  <h4>JAVA APPS</h4>
                </article>
              </div>

              <div class="col-lg-3 col-md-6">
                <figure class="wow fadeinUp">
                  {" "}
                  <img src="./assets/images/icon08.png" alt="Image" />
                </figure>
                <article>
                  <span>08</span>
                  <h4>E-COMMERCE</h4>
                </article>
              </div>
            </div>
          </div>
        </section>

        <section class="logos">
          <div class="container">
            <div class="titles">
              <h6>CLIENTS WHO TRUST US</h6>
              <p>
                We’re constantly refining our product. Adding new features.
                Working to help your business grow.
              </p>
            </div>

            <ul class="wow fadeInUp">
              <li>
                <figure>
                  <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
                  <img src="/assets/images/powtoon-logo-1.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Waypoint.png" alt="Image" />
                  <img src="/assets/images/Waypoint.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CuticaHealth.png" alt="Image" />
                  <img src="/assets/images/CuticaHealth.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CDP.png" alt="Image" />
                  <img src="/assets/images/CDP.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CobbleHyde.png" alt="Image" />
                  <img src="/assets/images/CobbleHyde.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/CPTCSA.png" alt="Image" />
                  <img src="/assets/images/CPTCSA.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/ECGroup.png" alt="Image" />
                  <img src="/assets/images/ECGroup.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/hecs_logo.png" alt="Image" />
                  <img src="/assets/images/hecs_logo.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Jebathottam.png" alt="Image" />
                  <img src="/assets/images/Jebathottam.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Comforter.png" alt="Image" />
                  <img src="/assets/images/Comforter.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/JFamilychannel.png" alt="Image" />
                  <img src="/assets/images/JFamilychannel.png" alt="Image" />
                </figure>
              </li>

              <li>
                <figure>
                  <img src="/assets/images/Bimarketing.png" alt="Image" />
                  <img src="/assets/images/Bimarketing.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/jesuscalls.png" alt="Image" />
                  <img src="/assets/images/jesuscalls.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Mizpah.png" alt="Image" />
                  <img src="/assets/images/Mizpah.png" alt="Image" />
                </figure>
              </li>
              <li>
                <figure>
                  <img src="/assets/images/Sathiya.png" alt="Image" />
                  <img src="/assets/images/Sathiya.png" alt="Image" />
                </figure>
              </li>
            </ul>
          </div>
        </section>

        <section class="work-with-us" data-color="dark">
          <div class="container wow fadeInUp">
            <h6>WORK WITH PRO TEAM</h6>
            <h2>
              Would you like to have professional project? Let's talk about it!
            </h2>
            <p>
              {" "}
              <a href="mailto:hello@atminds.com">hello@atminds.com</a>
            </p>
            <small>Or</small>
            <p>
              <a href="tel:+91 89396 77750">+91 89396 77750</a>
            </p>
          </div>
        </section>

        <section class="works">
          <div class="container">
            <ul>
              <li>
                <div class="titles">
                  <h2>EXPLORE OUR ALL WORKS</h2>
                  <h4>
                    Adding new features. Working to help your section business
                    grow. We’re constantly refining our product.
                  </h4>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works01.jpg" data-fancybox>
                      <img src="./assets/images/works01.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works01.jpg" data-fancybox>
                        Moments Matter Event Studio
                      </a>
                    </h3>
                    <small>Digital, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>

              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/tv.jpg" data-fancybox>
                      <img src="./assets/images/tv.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/tv.jpg" data-fancybox>
                        Comforter TV - OTT Platform
                      </a>
                    </h3>
                    <small>Development, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works04.jpg" data-fancybox>
                      <img src="./assets/images/works04.jpg" alt="Image" />{" "}
                    </a>
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works04.jpg" data-fancybox>
                        David Courtney IOS Application
                      </a>
                    </h3>
                    <small>Application, Branding</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works03.jpg" data-fancybox>
                      <img src="./assets/images/works03.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works03.jpg" data-fancybox>
                        Stan's Office Online Gateway
                      </a>
                    </h3>
                    <small>Branding, eCommerce, Print</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works05.jpg" data-fancybox>
                      <img src="./assets/images/works05.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works05.jpg" data-fancybox>
                        Daria e-commerce IOS Application
                      </a>
                    </h3>
                    <small>eCommerce, Web Design</small>{" "}
                  </div>
                </div>
              </li>
              <li>
                <div class="project-box">
                  <figure class="project-image reveal-effect masker wow">
                    {" "}
                    <a href="./assets/images/works06.jpg" data-fancybox>
                      <img src="./assets/images/works06.jpg" alt="Image" />
                    </a>{" "}
                  </figure>
                  <div class="project-content">
                    <h3>
                      <a href="./assets/images/works06.jpg" data-fancybox>
                        Get Yo Tacos IOS Application
                      </a>
                    </h3>
                    <small>Application, Mobile</small>{" "}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}

export default WhoWeAre;
