import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
} from "react-router-dom";

function Footer() {
  return (
    <>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 wow fadeInUp">
              <figure class="logo">
                {" "}
                <a href="/">
                  <img src="./assets/images/atmindsNewlogo.png" alt="Image" />{" "}
                </a>
              </figure>

              <h4>@minds | Tech Minds at work</h4>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp">
              <h5>Location</h5>
              <address>
                <strong>@Minds Technologies</strong>
                <br />
                163, South Thirumalai nagar
                <br />
                ICF, Chennai 600049, Tamil Nadu.
              </address>
            </div>
            <div class="col-lg-3 col-md-6 wow fadeInUp">
              <h5>Say Hello</h5>
              <address>
                <a href="mailto:hello@atminds.com">hello@atminds.com</a>

                <br />
                <a href="tel:+91 89396 77750">+91 89396 77750</a>
              </address>
            </div>
            <div class="col-12 wow fadeInUp">
              <div class="sub-footer">
                <span>© 2023 AtMinds Technologies - All rights reserved.</span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
