import React, { Component, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,
  useParams,
} from "react-router-dom";
import Axios from "axios";
import Moment from "moment";

export default function BlogDetail() {

  const [category, setCategory] = useState([]);
  const [posts, setallPosts] = useState([]);
  const [catposts, setcatPosts] = useState([]);
  let { id } = useParams();
  const apiUrl = "https://backend.atminds.com/";
  const imgApiUrl = "https://demo.atminds.com/public/storage/";

  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/categories`).then((data) => {
      // console.log(data);
      setCategory(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/post`).then((data) => {
      // console.log(data);
      setallPosts(data.data);
    });
  }, []);

  useEffect(() => {
    Axios.get(`${apiUrl}/api/get/post/${id}`).then((data) => {
      console.log("cat_post", data);
      setcatPosts(data.data);
    });
  }, []);

  const [current, setCurrent] = useState(id);
  console.log("id", current);

  // const pre = catposts < current;
  // console.log('pre',pre)
  const state = {
    items: [],
    currentItem: null,
    index: 0,
  };

  const handleNext = () => {
    const { index, items } = this.state;
    const nextQuestion = index + 1;

    this.setState({ currentItem: items[nextQuestion], index: nextQuestion });
  };
  const { currentItem } = state;

  return (
    <>
      <section class="blog">
        <div class="container">
          <div class="row">
            <div class="col-lg-8">
              {catposts.map((user, key) => (
                <div class="post-body wow fadeIn">
                  <h3 class="post-title">{user.name}</h3>
                  <small class="post-date">
                    {Moment(user.created_at).format("DD MMM, yyyy")}
                  </small>
                  <div class="avatar">
                    <img
                      src="./assets/images/53a98c10f3d7f4ec0c25e8f7d65b622a.png"
                      alt="Image"
                    />
                    by
                    <a className="pl-2" href="#">
                      Admin
                    </a>
                  </div>
                  <div class="post-header wow fadeIn pb-5">
                    <figure class="post-image">
                      <img src={`${imgApiUrl}` + user.image} alt="Images" />
                    </figure>
                  </div>
                  {/* {user.content} */}
                  <div dangerouslySetInnerHTML={{ __html: user.content }} />
                </div>
              ))}

              {/* <div class="post-navigation mb-5">
                <a href="#">
                  <button onClick={handleNext}>
                    Three-piece set with matching headband and knickers.
                  </button>
                </a>
                <a href="#">County suffer twenty or marked no moment in he.</a>
              </div> */}
            </div>


            <div class="col-lg-4">
                <aside class="sidebar">
                  <div class="widget">
                    {/* <div class="widget">
                      <h4 class="title">SEARCH</h4>
                      <form>
                        <input type="text" placeholder="Search on blog" />
                        <button type="submit">SEARCH</button>
                      </form>
                    </div> */}

                    <div class="widget">
                      <h4 class="title">RECENT POSTS</h4>
                      <ul class="recent">
                        {posts.map((user, key) => (
                          <li>
                            <a href="#">
                              <Link to={`/blog-detail/${user.id}`}>
                                {user.name}
                              </Link>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div class="widget">
                      <h4 class="title">CATEGORIES</h4>
                      <ul class="tags">
                        {category.map((user, key) => (
                          <li>
                            <a href="#">
                              <Link to={`/category/${user.id}`}>
                                {user.name}
                              </Link>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
